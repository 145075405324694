import { useState } from "react";
import logo from "../../assets/imgs/logo.png";

export default function Navigation(props) {
  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
        onMouseLeave={() => {
          setShowing(false);
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.clear();
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            setShowing(true);
          }}
          className="item"
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "auto 1fr auto" : "auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          {params.showing && <p>{params.txt}</p>}
          {params.options.length > 0 && params.showing && (
            <i className="fa fa-angle-right"></i>
          )}
        </div>
        {showing &&
          params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
          src={logo}
          alt=""
        />
        {props.showing && (
          <>
            <h3>Kitui County</h3>
            <h3>Tree Mapping</h3>
            <hr />
          </>
        )}
      </div>
      <Item
        url="/"
        txt="Home"
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/gis"
        txt="GIS"
        icon="fa-map"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Data"
        icon="fa-tasks"
        url="/data"
        options={[]}
        showing={props.showing}
      />
      {/* <Item
        txt="Data"
        url="/data"
        icon="fa-database"
        options={[
          "Schools",
          "Factories",
          "Industries",
          "Hospitals",
          "Boreholes",
        ]}
        showing={props.showing}
      /> */}
      <Item
        txt="Portal Users"
        url="/portal"
        icon="fa-user-md"
        options={["Users", "New"]}
        showing={props.showing}
      />
      <Item
        txt="Mobile Users"
        url="/mobile"
        icon="fa-user"
        options={["Users", "New"]}
        showing={props.showing}
      />
      <Item
        txt="Settings"
        url="/settings"
        icon="fa-gear"
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        icon="fa-lock"
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}
